import Image from "next/image"
import {CompanyName, ImageLoader, StaticImagesVersion} from "../config"
import Link from "next/link"

export default function Footer(props) {
	return (
		<footer>
			<div className="container pt-3">
				<div className="row py-5 px-3 d-flex justify-content-center main-footer-layer footer-layer">
					<div className="mb-5 mb-sm-5 mb-md-5 mb-lg-0 col-md-12 col-lg-4 d-flex flex-column justify-content-center align-items-center">
						<div className="footer-company-icon position-relative">
							<Image loader={ImageLoader} layout="fill" objectFit="contain" loading="lazy" src={"/images/logo.png?v=" + StaticImagesVersion} alt={CompanyName}/>
						</div>
						<div className="py-2"></div>
						<div className="footer-company-name">صرافی {CompanyName}</div>
						<div className="py-1"></div>
						<p className="mb-0">خرید و فروش فوری، آسان و امن</p>
						<div className="py-2"></div>
						<div><a href={"#"} className="design-simple-btn">testmail@gmail.com</a><span className="px-1">|</span><a href={"#"} className="design-simple-btn">09350000000</a></div>
					</div>
					<div className="mb-4 mb-sm-0 mb-md-0 mb-lg-0 col-6 col-sm-4 col-md-3 col-lg-2 d-flex flex-column">
						<div className="mb-3 footer-hyperlink-text">قیمت لحظه‌ای</div>
						<ul className="footer-hyperlink-subs-box">
							<li className="mb-3"><Link href={""}><a className="design-footer-sub-btn">بیت کوین</a></Link></li>
							<li className="mb-3"><Link href={""}><a className="design-footer-sub-btn">اتریوم</a></Link></li>
							<li className="mb-3"><Link href={""}><a className="design-footer-sub-btn">ترون</a></Link></li>
							<li className="mb-3"><Link href={"currencies"}><a className="design-footer-sub-btn">همه ارز ها</a></Link></li>
						</ul>
					</div>
					<div className="mb-4 mb-sm-0 mb-md-0 mb-lg-0 col-6 col-sm-4 col-md-3 col-lg-2 d-flex flex-column">
						<div className="mb-3 footer-hyperlink-text">خرید و فروش</div>
						<ul className="footer-hyperlink-subs-box">
							<li className="mb-3"><Link href={"/btc"}><a className="design-footer-sub-btn">بیت کوین</a></Link></li>
							<li className="mb-3"><Link href={"/bnb"}><a className="design-footer-sub-btn">بایننس کوین</a></Link></li>
							<li className="mb-3"><Link href={"/ada"}><a className="design-footer-sub-btn">کاردانو</a></Link></li>
							<li className="mb-3"><Link href={"/shib"}><a className="design-footer-sub-btn">شیبا اینو</a></Link></li>
						</ul>
					</div>
					<div className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex flex-column">
						<div className="mb-3 footer-hyperlink-text">حساب کاربری</div>
						<ul className="footer-hyperlink-subs-box">
							<li className="mb-3"><Link href={""}><a className="design-footer-sub-btn">راهنمای احراز هویت</a></Link></li>
							<li className="mb-3"><Link href={"/user-levels"}><a className="design-footer-sub-btn">سطوح کاربری</a></Link></li>
							<li className="mb-3"><Link href={"/fees"}><a className="design-footer-sub-btn">کارمزدها</a></Link></li>
							<li className="mb-3"><Link href={"/terms"}><a className="design-footer-sub-btn">قوانین و مقررات</a></Link></li>
						</ul>
					</div>
					<div className="col-6 col-sm-12 col-md-12 col-lg-2 footer-socialmedias-layer d-flex justify-content-center align-items-center">
						<Link href={"#"}>
							<div className="footer-socialmedias-box c-transition">
								<svg className="d-block w-100 h-100" viewBox="0 0 512 512"><path fill="CurrentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/></svg>
							</div>
						</Link>
						<Link href={"#"}>
							<div className="footer-socialmedias-box c-transition">
								<svg className="d-block w-100 h-100" viewBox="0 0 448 512"><path fill="CurrentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
							</div>
						</Link>
						<Link href={"#"}>
							<div className="footer-socialmedias-box c-transition">
								<svg className="d-block w-100 h-100" viewBox="0 0 496 512"><path fill="CurrentColor" d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z"/></svg>
							</div>
						</Link>
					</div>
				</div>
				<div className="row py-4">
					<p className="text-center mb-0 text-white">ALL rights reserved - © Copy Right 2022</p>
				</div>
			</div>
		</footer>
	)
}
