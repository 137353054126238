import Footer from "./footer"
import Header from "./header"
import Login from "./login"

export default function Template({ children }) {
	return (
		<>
			<Login/>
			<Header/>
			{children}
			<Footer/>
		</>
	)
}
